import { graphql } from 'gatsby'
import React from 'react'
import Exhibit from '../components/Exhibit'
import SEO from '../components/SEO'

const EventTemplate = ({ data, pageContext }) => {
  const {
    seoDescription,
    name,
    nameImage,
    dateText,
    eventIntro,
    bookingUrl,
    art,
  } = data.contentfulEvent

  return (
    <>
      <SEO title={name} description={seoDescription?.seoDescription} />
      <Exhibit
        type="event"
        title={name}
        name={name}
        nameImage={nameImage}
        label={dateText}
        intro={eventIntro}
        bookingUrl={bookingUrl}
        next={pageContext.next}
        art={art}
      />
    </>
  )
}

export default EventTemplate

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      seoDescription {
        seoDescription
      }
      name
      nameImage {
        file {
          url
        }
      }
      dateText
      eventIntro {
        raw
      }
      bookingUrl
      art {
        type: __typename
        ... on Node {
          ... on ContentfulImage {
            title
            description
            file {
              file {
                details {
                  image {
                    width
                  }
                }
              }
              gatsbyImageData(quality: 80)
            }
          }
          ... on ContentfulVideo {
            title
            description
            vimeoId
          }
        }
      }
    }
  }
`
